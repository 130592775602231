import React from "react";
import Typewriter from "typewriter-effect";
import { CSSTransition } from "react-transition-group";
import "./hero.css";

const Hero = () => {
  const [inProp, setInProp] = React.useState(false);

  React.useEffect(() => {
    setInProp(true);
  }, []);

  return (
    <section id="hero" className="container hero h-100 ">
      <div className="row d-flex justify-content-end align-items-end w-100">
        <div className="col-md-4 col-12 mb-md-0 text-md-left">
          <CSSTransition in={inProp} timeout={500} classNames="slide">
            <h1>Visual Design</h1>
          </CSSTransition>
        </div>
        <div className="col-md-8 col-12 d-flex justify-content-md-start">
          <Typewriter
            options={{
              delay: 20,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  "Specialized in Motion Graphics, <br /> UI/UX and Graphic Design"
                )
                .pauseFor(2500)
                .callFunction(() => {
                  console.log("String typed out!");
                })
                .start();
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
