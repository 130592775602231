import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import "./header.css";

import SwitchIconLight from "../../assets/switch-icon.svg";
import SwitchIconDark from "../../assets/switch-icon-dark.svg";

const Header = () => {
  const [colorMode, setColorMode] = useState("light");
  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const savedColorMode = localStorage.getItem("colorMode");
    if (savedColorMode) {
      setColorMode(savedColorMode);
    } else {
      localStorage.setItem("colorMode", "light");
      setColorMode("light");
    }
  }, []);

  const colorSwitchHandler = () => {
    const newColorMode = colorMode === "dark" ? "light" : "dark";
    localStorage.setItem("colorMode", newColorMode);
    setColorMode(newColorMode);
  };

  useEffect(() => {
    const isDarkMode = localStorage.getItem("colorMode") === "dark";
    setDarkMode(isDarkMode);
  }, [colorMode]);

  useEffect(() => {
    const body = document.querySelector("body");
    const headerLogo = document.querySelector(".navbar-brand");
    const navLinks = document.querySelectorAll(".nav-link");
    const viewProjectParagraph = document.querySelector(".view-project p");
    const viewProjectCategory = document.querySelectorAll(".category");
    const viewProjectSoftwareSvg = document.querySelectorAll(
      ".software-icon div svg path"
    );
    const pagginationBtn = document.querySelectorAll(".MuiPaginationItem-root");
    const header = document.querySelector("header");
    const viewProjectPage = document.querySelector(".view-project");

    if (darkMode) {
      header.style.background = "black";
      if (body) {
        body.classList.add("dark-mode");
        navLinks.forEach((link) => {
          link.classList.add("dark-mode");
        });
        headerLogo.classList.add("dark-mode");
        // pagginationBtn.forEach((btn) => {
        //   btn.classList.add("paggination-btn-dark-mode");
        // });
      }
      if (viewProjectPage) {
        viewProjectCategory.forEach((category) => {
          category.style.backgroundColor = "white";
          category.style.color = "black";
        });
        viewProjectParagraph.classList.add("dark-mode");
        viewProjectSoftwareSvg.forEach((svg) => {
          svg.setAttribute("fill", "white");
        });
      }
    } else {
      header.style.background = "white";
      // pagginationBtn.forEach((btn) => {
      //   btn.classList.remove("paggination-btn-dark-mode");
      // });
      if (body) {
        body.classList.remove("dark-mode");
        headerLogo.classList.remove("dark-mode");
        navLinks.forEach((link) => {
          link.classList.remove("dark-mode");
        });
      }
      if (viewProjectPage) {
        viewProjectParagraph.classList.remove("dark-mode");
        viewProjectCategory.forEach((category) => {
          category.style.backgroundColor = "black";
          category.style.color = "white";
        });
        viewProjectSoftwareSvg.forEach((svg) => {
          svg.setAttribute("fill", "black");
        });
      }
    }
  }, [darkMode]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    position: "relative",
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "transparent" : "#C3C3C3",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      position: "relative",
      boxSizing: "border-box",
      width: 22,
      height: 22,
      transition: theme.transitions.create(["transform", "opacity"], {
        duration: 1000,
      }),
    },
    "& .MuiSwitch-thumb:before": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      width: 20,
      height: 20,
      backgroundImage: `url(${
        colorMode === "light" ? SwitchIconLight : SwitchIconDark
      })`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      transform: "translate(-50%, -50%)",
      transition: "opacity 0.3s ease",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#666666" : "#C3C3C3",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const renderSwitch = () => {
    return (
      <div className="color-switch d-flex ms-lg-3 mt-2 mt-lg-0">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={colorMode === "light"}
              onChange={colorSwitchHandler}
              sx={{ m: 1 }}
            />
          }
        />
      </div>
    );
  };

  return (
    <header className="container pt-3">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            Mirza Kurbegović
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse gap-5 align-items-center"
            id="navbarNav"
          >
            {isHomePage ? (
              <ul className="navbar-nav gap-4 ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="about"
                    containerId="scrollableContainer"
                    smooth={true}
                    duration={500}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="works"
                    containerId="scrollableContainer"
                    smooth={true}
                    duration={500}
                  >
                    Work
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="contact"
                    containerId="scrollableContainer"
                    smooth={true}
                    duration={500}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav gap-4 ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#works">
                    Work
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#contact">
                    Contact
                  </a>
                </li>
              </ul>
            )}
            <div className="color-switch d-flex ms-lg-3 mt-2 mt-lg-0">
              {renderSwitch()}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
