import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { ProjectsContext } from "../../context/projectsContext";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import "./works.css";

const categories = ["all work", "graphic design", "motion graphics", "3D"];
const projectsPerPage = 7;

const Works = () => {
  const { projects } = useContext(ProjectsContext);
  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [hoveredProject, setHoveredProject] = useState(null);
  const tooltipRef = useRef(null);
  const navigate = useNavigate();
  const animationFrameId = useRef(null);

  useEffect(() => {
    projects.forEach((project) => {
      if (!Array.isArray(project.category)) {
        project.category = [project.category];
      }
    });
  }, [projects]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const targetX = event.clientX + 10;
      const targetY = event.clientY + 10;

      const updatePosition = () => {
        setTooltipPosition((prevPosition) => {
          const dx = targetX - prevPosition.x;
          const dy = targetY - prevPosition.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 1) {
            cancelAnimationFrame(animationFrameId.current);
            return { x: targetX, y: targetY };
          }

          const angle = Math.atan2(dy, dx);
          const velocity = 0.1;
          const newX = prevPosition.x + Math.cos(angle) * velocity * distance;
          const newY = prevPosition.y + Math.sin(angle) * velocity * distance;

          return { x: newX, y: newY };
        });

        animationFrameId.current = requestAnimationFrame(updatePosition);
      };

      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = requestAnimationFrame(updatePosition);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      cancelAnimationFrame(animationFrameId.current);
    };
  }, []);

  const filteredAndSortedProjects = projects
    .filter((project) =>
      activeCategory === "all work"
        ? true
        : project.category.includes(activeCategory)
    )
    .sort((a, b) => b.index - a.index);

  const pageCount = Math.ceil(
    filteredAndSortedProjects.length / projectsPerPage
  );
  const indexOfFirstProject = (currentPage - 1) * projectsPerPage;
  const indexOfLastProject = indexOfFirstProject + projectsPerPage;
  const currentProjects = filteredAndSortedProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const handleProjectClick = (project) => {
    const projectName = project.name.replace(/\s+/g, "-").toLowerCase();
    navigate(`/project/${projectName}`);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleMouseEnter = (event, project) => {
    setTooltipPosition({ x: event.clientX, y: event.clientY });
    setHoveredProject(project);
  };

  const handleMouseLeave = () => {
    setHoveredProject(null);
  };

  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section id="works" className="container works">
      <div className="row">
        <div className="col-12 d-flex justify-content-start">
          <h1
            ref={headingRef}
            className={`${
              headingInView || window.innerWidth <= 768
                ? "slide-in-left"
                : "slide-in-left-hidden"
            }`}
          >
            Work
          </h1>
        </div>
        <div className="col-12 mt-3">
          <ul className="d-flex justify-content-start gap-3 gap-md-5 flex-wrap categories-container">
            {categories.map((category) => (
              <li
                key={category}
                className={`category-item ${
                  category === activeCategory ? "active" : ""
                }`}
                onClick={() => {
                  setActiveCategory(category);
                  setCurrentPage(1);
                }}
              >
                <span>{category}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 mt-4">
          <ul className="project-list">
            {currentProjects.map((project) => (
              <li
                key={project.id}
                className="project-item"
                onClick={() => handleProjectClick(project)}
                onMouseEnter={(e) => handleMouseEnter(e, project)}
                onMouseLeave={handleMouseLeave}
              >
                <span>{project.name}</span>
                <span className="project-categories">
                  {project.category.map((cat, index) => (
                    <span key={index} className="category-item">
                      {cat}
                      {index < project.category.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {hoveredProject && (
          <div
            ref={tooltipRef}
            className={`project-tooltip ${hoveredProject ? "show" : ""}`}
            style={{
              top: tooltipPosition.y + "px",
              left: tooltipPosition.x + "px",
            }}
          >
            <img
              src={hoveredProject.gallery[0]}
              alt={hoveredProject.name}
              className="tooltip-image"
            />
          </div>
        )}
        <div className="col-12 mt-5 d-flex justify-content-center">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            renderItem={(item) => <PaginationItem {...item} />}
          />
        </div>
      </div>
    </section>
  );
};

export default Works;
