import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Context
import { ProjectsProvider } from "./context/projectsContext";

// Components
import { Header } from "./components";

import { Login, ProjectSubmit } from "./admin";
import { Home, ViewProject, NotFound } from "./pages";
// import { ScrollDots } from "./components";

import ProtectedRoute from "./admin/protectedRoute";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [auth, setAuth] = useState(false);

  return (
    <ProjectsProvider>
      <Router>
        <Header />
        <ToastContainer />
        {/* <ScrollDots /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project/:name" element={<ViewProject />} />
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/admin/submit-project"
            element={
              <ProtectedRoute auth={auth}>
                <ProjectSubmit />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ProjectsProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
