import React from "react";
import { useInView } from "react-intersection-observer";
import { InstagramIcon, LinkedinIcon, BehanceIcon } from "../../assets/socials";
import "./about.css";

const About = () => {
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: iconsRef, inView: iconsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section
      id="about"
      className="container about d-flex align-items-center flex-column justify-content-center"
    >
      <div className="col-12 d-flex flex-column align-items-center justify-content-center">
        <h1
          ref={headingRef}
          className={`${
            headingInView ? "slide-in-left" : "slide-in-left-hidden"
          }`}
        >
          About me
        </h1>
        <p
          ref={textRef}
          className={`text-center mt-2 ${
            textInView ? "fade-in" : "fade-in-hidden"
          }`}
        >
          Experienced graphic & motion designer with 7 years experience in the
          industry with expertise in UI/UX, branding, product design, social
          media branding, and 2D/3D animation.
        </p>
        <div
          ref={iconsRef}
          className={`row  d-flex align-items-center justify-content-center social-icons-container ${
            iconsInView ? "fade-in" : "fade-in-hidden"
          }`}
        >
          <div className="col-4  d-flex justify-content-center">
            <img src={LinkedinIcon} alt="linkedin" className="social-icon" />
          </div>
          <div className="col-4  d-flex justify-content-center">
            <img src={InstagramIcon} alt="instagram" className="social-icon" />
          </div>
          <div className="col-4 d-flex justify-content-center">
            <img src={BehanceIcon} alt="behance" className="social-icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
