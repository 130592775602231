import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { ProjectsContext } from "../../context/projectsContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
} from "mdb-react-ui-kit";
import ReactPlayer from "react-player";
import { Contact } from "../../containers";
import BackArrow from "../../assets/back.svg";
import LeftArrow from "../../assets/left.png";
import RightArrow from "../../assets/right.png";
import playIcon from "../../assets/play.svg";
import LinkIcon from "../../assets/link.svg";
import "./viewProject.css";

const ViewProject = () => {
  const { projects } = useContext(ProjectsContext);
  const { name } = useParams();
  const formattedName = name.replace(/-/g, " ");
  const [icons, setIcons] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [playingVideos, setPlayingVideos] = useState({});
  const videoRefs = useRef([]);

  useEffect(() => {
    const getIcons = async () => {
      try {
        const iconsCollection = collection(db, "icons");
        const iconsSnapshot = await getDocs(iconsCollection);
        const iconsList = iconsSnapshot.docs.map((doc) => doc.data());
        setIcons(iconsList);
        console.log("Fetched Icons:", iconsList);
      } catch (error) {
        console.error("Error fetching icons:", error);
      }
    };
    getIcons();
  }, []);

  const selectedProject = projects.find(
    (project) => project.name.toLowerCase() === formattedName
  );

  if (!selectedProject) {
    return <div>Project not found</div>;
  }

  const { description, category, software, gallery, videoUrl, url } =
    selectedProject;

  const softwaresUsed = software.map((softwareItem) => {
    const matchedIcon = icons.find(
      (icon) => icon.name.toLowerCase() === softwareItem.toLowerCase()
    );
    return matchedIcon ? (
      <div key={softwareItem} className="software-icon">
        <div dangerouslySetInnerHTML={{ __html: matchedIcon.svg }} />
      </div>
    ) : (
      <div key={softwareItem} className="software-icon">
        {softwareItem}
      </div>
    );
  });

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleNextMedia = () => {
    const nextIndex = (selectedMediaIndex + 1) % gallery.length;
    setSelectedMediaIndex(nextIndex);
    setSelectedMedia(gallery[nextIndex]);
  };

  const handlePrevMedia = () => {
    const prevIndex =
      (selectedMediaIndex - 1 + gallery.length) % gallery.length;
    setSelectedMediaIndex(prevIndex);
    setSelectedMedia(gallery[prevIndex]);
  };

  const handleMediaClick = (index) => {
    if (!isVideo(gallery[index])) {
      setSelectedMediaIndex(index);
      setSelectedMedia(gallery[index]);
      toggleModal();
    }
  };

  const isVideo = (file) => {
    return file.includes(".mp4");
  };

  const handlePlayPause = (index) => {
    setPlayingVideos((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleVideoPlay = (index) => {
    setPlayingVideos((prev) => ({ ...prev, [index]: true }));
  };

  const handleVideoPause = (index) => {
    setPlayingVideos((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <section className="view-project container">
      <MDBModal
        show={modal}
        className="modal-image"
        onHide={toggleModal}
        tabIndex="-1"
        setShow={setModal}
      >
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <button
                type="button"
                className="btn-close"
                onClick={toggleModal}
              ></button>
            </MDBModalHeader>
            <MDBModalBody className="modal-body">
              <button onClick={handlePrevMedia}>
                <img src={LeftArrow} alt="Previous" />
              </button>
              <img
                src={selectedMedia}
                alt="Enlarged project"
                className="media"
                style={{ borderRadius: "10px", maxWidth: "100%" }}
              />
              <button onClick={handleNextMedia}>
                <img src={RightArrow} alt="Next" />
              </button>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <div className="project-info pb-5">
        <h2>{selectedProject.name}</h2>
        {description.length > 0 ? (
          <p className="mt-3">{description}</p>
        ) : (
          <p className="mt-3">No description specified.</p>
        )}
        {url.length > 0 && (
          <button
            onClick={() => {
              window.open(url, "_blank");
            }}
            className="link-to-project mt-5"
          >
            <img src={LinkIcon} alt="icon" />
            Link
          </button>
        )}
        <div className="project-categories mt-3">
          <div className="d-flex flex-direction-row gap-3 mt-3">
            {category.map((categoryItem) => (
              <div key={categoryItem} className="category">
                {categoryItem}
              </div>
            ))}
          </div>
        </div>
        <div className="software-list mt-5">
          <p>Softwares Used</p>
          <div className="d-flex gap-3 mt-3">{softwaresUsed}</div>
        </div>
        <img
          src={BackArrow}
          alt="Back"
          className="back-btn"
          onClick={() => window.history.back()}
        />
        {gallery.length > 0 && (
          <MDBRow className="mt-5">
            {gallery.map((item, mediaIndex) => (
              <MDBCol key={mediaIndex} lg={12} md={12} className="mb-lg-0">
                {isVideo(item) ? (
                  <div
                    className="video-container"
                    style={{ position: "relative" }}
                  >
                    <ReactPlayer
                      url={item}
                      controls
                      className="media video"
                      playing={!!playingVideos[mediaIndex]}
                      onPlay={() => handleVideoPlay(mediaIndex)}
                      onPause={() => handleVideoPause(mediaIndex)}
                    />
                    {!playingVideos[mediaIndex] && (
                      <img
                        src={playIcon}
                        alt="Play"
                        className="play-icon hide-on-phone"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePlayPause(mediaIndex);
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <img
                    src={item}
                    className="media"
                    alt={`Project image ${mediaIndex + 1}`}
                    onClick={() => handleMediaClick(mediaIndex)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </MDBCol>
            ))}
          </MDBRow>
        )}
        {videoUrl && (
          <div className="video-container d-flex mt-5">
            <ReactPlayer
              url={`https://www.youtube.com/embed/${
                videoUrl.split("v=")[1].split("&")[0]
              }`}
              controls
              width="50%"
              height="315px"
              className="img-fluid"
            />
          </div>
        )}
        <Contact />
      </div>
    </section>
  );
};

export default ViewProject;
