import React, { useState } from "react";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.subject ||
      !formData.message
    ) {
      return toast.error("All fields are required!");
    }

    try {
      await axios.post(
        "https://mirza-portfolio-server.onrender.com/send-email",
        formData
      );
      toast.success("Message sent successfully!");
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    }
  };

  return (
    <section id="contact" className="container contact">
      <div className="row">
        <div className="col-12 d-flex justify-content-start">
          <h1 ref={headingRef}>Contact</h1>
        </div>
        <div className="col-12 mt-5">
          <form onSubmit={handleSubmit}>
            <div className="mb-4 row">
              <div className="col-md-6 col-12 mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name:"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 col-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email:"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="subject"
                required
                placeholder="Message Subject:"
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="message"
                rows="4"
                required
                placeholder="Message:"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="mt-5">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
