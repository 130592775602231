import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { storage } from "../../firebaseConfig";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ProjectSubmit = () => {
  const categories = ["graphic design", "motion graphics", "3D"];
  const softwares = [
    "Adobe Illustrator",
    "Adobe Photoshop",
    "Adobe After Effects",
    "Adobe Premiere Pro",
    "Cinema 4D",
    "Keyshot",
    "Figma",
  ];

  const [project, setProject] = useState({
    name: "",
    description: "",
    url: "",
    videoUrl: "",
    category: [],
    software: [],
    gallery: [],
  });
  const [newGallery, setNewGallery] = useState([]);
  const [editingProject, setEditingProject] = useState(null);
  const [projects, setProjects] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    if (type === "checkbox") {
      if (name === "category") {
        setProject((prev) => ({
          ...prev,
          category: checked
            ? [...prev.category, value]
            : prev.category.filter((c) => c !== value),
        }));
      } else if (name === "software") {
        setProject((prev) => ({
          ...prev,
          software: checked
            ? [...prev.software, value]
            : prev.software.filter((s) => s !== value),
        }));
      }
    } else if (type === "file") {
      setNewGallery((prev) => [...prev, ...files]);
    } else {
      setProject((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleImageDelete = async (url) => {
    try {
      if (editingProject) {
        const fileRef = ref(storage, url);
        await deleteObject(fileRef);
        setProject((prev) => ({
          ...prev,
          gallery: prev.gallery.filter((image) => image !== url),
        }));
        setEditingProject((prev) => ({
          ...prev,
          gallery: prev.gallery.filter((image) => image !== url),
        }));
      } else {
        setNewGallery((prev) =>
          prev.filter((file) => URL.createObjectURL(file) !== url)
        );
      }
    } catch (error) {
      toast.error("Failed to delete image. Please try again later.");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const urls = editingProject ? [...editingProject.gallery] : [];
      for (const file of newGallery) {
        const fileRef = ref(storage, `gallery/${file.name}`);
        const snapshot = await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        urls.push(url);
      }

      const currentTime = new Date();
      const newIndex = editingProject
        ? editingProject.index
        : projects.length > 0
        ? Math.max(...projects.map((p) => p.index)) + 1
        : 0;

      const payload = {
        ...project,
        gallery: urls,
        dateAdded: editingProject ? editingProject.dateAdded : currentTime,
        index: newIndex,
      };

      let response;
      if (editingProject) {
        response = await axios.put(
          `https://mirza-portfolio-server.onrender.com/update-project/${editingProject.id}`,
          payload
        );
      } else {
        response = await axios.post(
          "https://mirza-portfolio-server.onrender.com/save-project",
          payload
        );
        setProjects((prevProjects) => [payload, ...prevProjects]);
      }

      if (response.status === 200) {
        toast.success(
          editingProject
            ? "Project updated successfully"
            : "Project submitted successfully"
        );
        fetchProjects();
        setProject({
          name: "",
          description: "",
          url: "",
          videoUrl: "",
          category: [],
          software: [],
          gallery: [],
        });
        setNewGallery([]);
        setEditingProject(null);
      } else {
        console.log(response);
        toast.error("Failed to submit project. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to submit project. Please try again later.");
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        "https://mirza-portfolio-server.onrender.com/get-projects"
      );
      setProjects(response.data);
      console.log(response.data, "res data fetch");
    } catch (error) {
      console.error("Failed to fetch projects", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://mirza-portfolio-server.onrender.com/delete-project/${id}`
      );
      toast.success("Project deleted successfully");
      fetchProjects();
    } catch (error) {
      toast.error("Failed to delete project. Please try again later.");
    }
  };

  const handleEdit = (project) => {
    setProject({
      ...project,
      gallery: project.gallery,
    });
    setNewGallery([]);
    setEditingProject(project);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedProjects = Array.from(projects);
    const [movedProject] = reorderedProjects.splice(result.source.index, 1);

    reorderedProjects.splice(result.destination.index, 0, movedProject);

    movedProject.index = projects.length;

    const updatedProjects = reorderedProjects.map((project, index) => ({
      ...project,
      index: projects.length - index - 1,
    }));

    setProjects(updatedProjects);

    await Promise.all(
      updatedProjects.map((project) => {
        const {
          id,
          name,
          description,
          url,
          videoUrl,
          category,
          software,
          gallery,
          dateAdded,
        } = project;
        return axios.put(
          `https://mirza-portfolio-server.onrender.com/update-project/${id}`,
          {
            index: project.index,
            name,
            description,
            url,
            videoUrl,
            category,
            software,
            gallery,
            dateAdded,
          }
        );
      })
    );
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Container className="container" style={{ marginTop: 100 }}>
      <h1 className="text-center">
        {editingProject ? "Edit Project" : "Submit Project"}
      </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={project.name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            value={project.description}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>URL</Form.Label>
          <Form.Control
            type="text"
            name="url"
            value={project.url}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Video URL</Form.Label>
          <Form.Control
            type="text"
            name="videoUrl"
            value={project.videoUrl}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Categories</Form.Label>
          {categories.map((category) => (
            <Form.Check
              key={category}
              type="checkbox"
              label={category}
              name="category"
              value={category}
              checked={project.category.includes(category)}
              onChange={handleChange}
            />
          ))}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Software used</Form.Label>
          {softwares.map((software) => (
            <Form.Check
              key={software}
              type="checkbox"
              label={software}
              name="software"
              value={software}
              checked={project.software.includes(software)}
              onChange={handleChange}
            />
          ))}
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Gallery</Form.Label>
          <Form.Control
            type="file"
            multiple
            name="gallery"
            onChange={handleChange}
          />
          <div className="mt-3 d-flex flex-wrap">
            {editingProject &&
              editingProject.gallery.map((url, index) => (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    position: "relative",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={url}
                    alt={`Gallery ${index}`}
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                  />
                  <Button
                    variant="danger"
                    size="sm"
                    style={{ position: "absolute", top: "0", right: "0" }}
                    onClick={() => handleImageDelete(url)}
                  >
                    X
                  </Button>
                </div>
              ))}
            {newGallery.map((file, index) => (
              <div
                key={index}
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`New Gallery ${index}`}
                  style={{ width: "100px", height: "100px" }}
                />
                <Button
                  variant="danger"
                  size="sm"
                  style={{ position: "absolute", top: "0", right: "0" }}
                  onClick={() => handleImageDelete(URL.createObjectURL(file))}
                >
                  X
                </Button>
              </div>
            ))}
          </div>
        </Form.Group>

        <Button className="mt-5" variant="primary" type="submit">
          {editingProject ? "Update" : "Submit"}
        </Button>
      </Form>

      <h2 className="text-center mt-5">Projects</h2>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable type="group" droppableId="project-droppable">
          {(provided) => (
            <Row {...provided.droppableProps} ref={provided.innerRef}>
              {projects.map((project, index) => (
                <Draggable
                  key={project.id}
                  draggableId={project.id}
                  index={index}
                >
                  {(provided) => (
                    <Col
                      md={12}
                      className="mb-4"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="project-card d-flex justify-content-between align-items-center">
                        <div>
                          <h3>{project.name}</h3>
                          <p>
                            {Array.isArray(project.category)
                              ? project.category.join(", ")
                              : project.category}
                          </p>
                        </div>
                        <div>
                          <Button
                            variant="secondary"
                            onClick={() => handleEdit(project)}
                            className="me-2"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(project.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </Col>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default ProjectSubmit;
