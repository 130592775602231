import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBtUOKRtrNK9WFQGkxAsa0hf403Qgatbt8",
  authDomain: "mirza-port.firebaseapp.com",
  projectId: "mirza-port",
  storageBucket: "mirza-port.appspot.com",
  messagingSenderId: "846495590485",
  appId: "1:846495590485:web:1653ba75b502ad3cdb8cdc",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
