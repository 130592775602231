import React from "react";
import { Hero, Works, Contact, About } from "../../containers";
import { Element } from "react-scroll";

import "./home.css";

const Home = () => {
  return (
    <div id="scrollableContainer">
      <Element name="section1" className="snap-scroll-section">
        <Hero />
      </Element>
      <Element name="about" className="snap-scroll-section">
        <About />
      </Element>
      <Element name="works" className="snap-scroll-section">
        <Works />
      </Element>
      <Element name="contact" className="snap-scroll-section">
        <Contact />
      </Element>
    </div>
  );
};

export default Home;
